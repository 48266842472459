import './App.css';
import React from 'react';

const App: React.FC<{}> = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={'/potato.png'} className="App-logo" alt="logo" />
        <h1 className="text-3xl font-bold underline">
          subtato
        </h1>
        I am potate.
      </header>
    </div>
  );
}

export default App;